import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./public/layout/public-layout/public-layout.component'),
    children: [
      {
        path: 'home',
        loadComponent: () => import('./public/pages/home/home.component')
      },
      {
        path: 'home/:id',
        loadComponent: () => import('./public/pages/home/home.component')
      },
      {
        path: 'product/:id',
        loadComponent: () => import('./public/pages/product/product.component')
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }

    ]
  },
  {
    path: 'app',
    loadComponent: () => import('./private/layout/private-layout/private-layout.component'),
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./private/components/dashboard/dashboard.component')
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }

    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];
